<template>
  <div class="container-fluid">
    <b-overlay :show="loader" rounded="sm">
      <div class="card card-default mt-5">
        <!-- /.card-header -->
        <div class="card-header">
          <h3
            class="card-title text-center"
            style="bottom: -15px !important; position: relative;"
          >
            Contact
          </h3>
          <div class="card-tools">
            <b-form inline class="pull-right">
              <div
                class="input-group input-group-sm pull-right"
                style="top:-16px !important"
              >
                <input
                  class="form-control input-search"
                  type="search"
                  v-model="filter"
                  placeholder="Search"
                  id="filterInput"
                  aria-label="Search"
                />
                <div class="input-group-append">
                  <div class="btn btn-navbar input-search">
                    <i class="fa fa-search"></i>
                  </div>
                </div>
              </div>
            </b-form>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-body content">
          <b-table
            id="contact-btable"
            striped
            hover
            outlined
            selectable
            :items="loadData"
            :fields="contactFields"
            :per-page="perPage"
            :current-page="currentPage"
            :no-provider-sorting="true"
            :select-mode="selectMode"
            :filter="filter"
            :filterIncludedFields="filterOn"
            @filtered="onFiltered"
            class=""
          >
            <template v-slot:cell(email)="data">
              <!-- <center> -->
              <span v-if="data.item.email">{{ data.item.email }}</span>
              <span v-else> - </span>
              <!-- </center> -->
            </template>
            <template v-slot:cell(mobile1)="data">
              <!-- <center> -->
              <span v-if="data.item.mobile1">{{ data.item.mobile1 }}</span>
              <span v-else> - </span>
              <!-- </center> -->
            </template>
            <template v-slot:cell(id)="data">
              <b-button
                size="sm"
                variant="outline-primary mx-1"
                class="fa fa-edit"
                @click="editContact(data)"
                v-b-popover.hover.top="'Edit'"
              ></b-button>
              <b-button
                size="sm"
                variant="outline-danger mx-1"
                @click="deleteContact(data.item)"
                class="fa fa-trash"
                v-b-popover.hover.top="'Delete'"
              ></b-button>
            </template>
          </b-table>
        </div>
        <!-- /.card-footer -->
        <div class="card-footer">
          <div class="col-xs-12">
            <b-pagination
              v-model="currentPage"
              :limit="10"
              :total-rows="totalRows"
              :per-page="perPage"
              size="sm"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
export default {
  name: "ContactsTable",
  props: ["showContactTable", "customerData"],
  data() {
    return {
      selectMode: "single",
      contacts: [],
      contactFields: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      filter: null,
      currentRows: 0,
      filterOn: [],
      loader: false,
    };
  },
  watch: {
    status: function(newVal, oldVal) {
      this.$root.$emit("bv::refresh::table", "contact-btable");
    },
  },
  methods: {
    loadData(ctx, callback) {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      let apiUrl = process.env.VUE_APP_URL + "customer/usercontact/getdata";
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          user_id: user_id,
          page: ctx.currentPage,
          size: ctx.perPage,
          filter: ctx.filter,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status == false) {
            this.$swal("Error", data.message, "error");
          }
          if (data.contact) {
            this.loader = false;
            this.currentRows = data.contact.length;
          }
          this.contact = data.contact;
          this.totalRows = data.totalRows;
          this.setFieldsForTable();
          callback(data.contact);
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    setFieldsForTable() {
      this.contactFields.push({
        contact_name: {
          label: "Name",
          sortable: true,
          class: "w-35",
        },
      });
      this.contactFields.push({
        email: {
          label: "Email",
          sortable: true,
          class: "w-35",
        },
      });
      this.contactFields.push({
        mobile1: {
          label: "Mobile",
          sortable: true,
          class: "w-20",
        },
      });
      this.contactFields.push({
        id: {
          label: "Actions",
          sortable: false,
          class: "w-30",
        },
      });
    },
    addContact() {
      this.$emit("addContact", true);
    },
    editContact(contact) {
      this.$emit("contactEdit", contact);
    },
    deleteContact(contact) {
      //console.log(contact);
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Do you want to remove  " + contact.contact_name + "!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            fetch(
              process.env.VUE_APP_URL + "customer/usercontact/" + contact.id,
              {
                method: "DELETE",
                headers: {
                  "content-type": "application/json",
                  "X-Requested-With": "XMLHttpRequest",
                  Authorization: "Bearer " + api_token,
                },
              }
            )
              .then((res) => res.json())
              .then((data) => {
                if (data.status === true) {
                  this.$root.$emit("bv::refresh::table", "contact-btable");

                  this.$swal.fire("Deleted", data.message, "success");
                } else {
                  this.$swal.fire("Error!", data.message, "error");
                }
              })
              .catch(function(err) {
                console.log("Fetch Error :-S", err);
              });
          } else {
            this.$root.$emit("bv::refresh::table", "contact-btable");
          }
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
